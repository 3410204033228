import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "category"
  ]

  connect() {
    this.changeCategory();
  }

  changeCategory() {
    $('.category__value').addClass('d-none')
    $('.category__amount-orders').addClass('d-none')

    const category = this.categoryTarget.value
    if (category == 'value') {
      $('.category__value').removeClass('d-none')
    }

    if (category == 'amount_orders') {
      $('.category__amount-orders').removeClass('d-none')
    }
  }
}