import { Controller } from "@hotwired/stimulus";

import "jquery-mask-plugin";
import "jquery-maskmoney/src/jquery.maskMoney";

export default class extends Controller {
  connect() {
    this.maskMoney();
    this.maskNumeric();
    this.maskMoneyCent();
  }

  maskMoney() {
    $(".inputmask")
      .maskMoney({
        decimal: ",",
        thousands: ".",
        precision: 2,
        affixesStay: true,
        allowZero: true,
      })
      .trigger("mask.maskMoney");
  }

  maskMoneyCent() {
    $(".inputmask")
      .maskMoney({
        decimal: ",",
        thousands: ".",
        precision: 2,
        affixesStay: true,
        allowZero: true
      }).trigger("mask.maskMoney");

    $(".inputMaskRate").attr('maxlength', 3);
  }

  maskNumeric() {
    $('.inputnumeric').on('input', function () {
      var value = $(this).val();
      value = value.replace(/[^0-9.]/g, '');
      if (parseFloat(value) >= 1) {
        $(this).val(value);
      } else {
        $(this).val('');
      }
    })
      .trigger("mask.maskNumeric");
  }
}
