export function getCardType(number) {
  var card_type = ''
  // visa
  // var re = new RegExp("^4");
  var re = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
  if (number.replace(/\s/g, '').match(re) != null)
    card_type = "visa";

  // Mastercard
  // re = new RegExp("^5[1-5]");
  re = new RegExp("^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$");
  if (number.replace(/\s/g, '').match(re) != null)
    card_type = "mastercard";

  // AMEX
  // re = new RegExp("^3[47]");
  re = new RegExp("^3[47][0-9]{13}$");
  if (number.replace(/\s/g, '').match(re) != null)
    card_type = "amex";

  // Discover
  // re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
  re = new RegExp("^6(?:011|5[0-9]{2})[0-9]{12}$");
  if (number.replace(/\s/g, '').match(re) != null)
    card_type = "discover";

  // Diners
  // re = new RegExp("^36"); diners
  re = new RegExp("^3(?:0[0-5]|[68][0-9])[0-9]{11}$"); //diners
  if (number.replace(/\s/g, '').match(re) != null)
    card_type = "diners_club";

  // JCB
  // re = new RegExp("^35(2[89]|[3-8][0-9])"); jcb
  re = new RegExp("^(35[0-9]{4}|356700|356704|356706|356708)[0-9]{10}$"); //jcb
  if (number.replace(/\s/g, '').match(re) != null)
    card_type = "jcb";

  // Elo
  re = new RegExp("^(401178|401179|431274|438935|451416|457393|457631|457632|504175|627780|636297|636369|(506699|5067[0-6]\d|50677[0-8])|(50900\d|5090[1-9]\d|509[1-9]\d{2})|65003[1-3]|(65003[5-9]|65004\d|65005[0-1])|(65040[5-9]|6504[1-3]\d)|(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|(65054[1-9]|6505[5-8]\d|65059[0-8])|(65070\d|65071[0-8])|65072[0-7]|(65090[1-9]|65091\d|650920)|(65165[2-9]|6516[6-7]\d)|(65500\d|65501\d)|(65502[1-9]|6550[3-4]\d|65505[0-8]))[0-9]{10,12}"); //elo
  if (number.replace(/\s/g, '').match(re) != null)
    card_type = "elo";

  // Hipercard
  re = new RegExp("^(38[0-9]{17}|60[0-9]{14})$"); //hipercard
  if (number.replace(/\s/g, '').match(re) != null)
    card_type = "hipercard";

  // Aura
  re = new RegExp("^50[0-9]{14,17}$"); //aura
  if (number.replace(/\s/g, '').match(re) != null)
    card_type = "aura";

  // Diners - Carte Blanche
  re = new RegExp("^30[0-5]"); //diners
  if (number.match(re) != null)
      card_type = "diners - carte blanche";

  // Visa Electron
  re = new RegExp("^(4026|417500|4508|4844|491(3|7))"); //visa electron
  if (number.match(re) != null)
      card_type = "visa electron";

  return card_type;
}
