import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  showLoadingState() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
    const loadingElement = document.querySelector(".loading-overlay");
    const body = document.querySelector("body");
    loadingElement.classList.remove("hidden");
    loadingElement.style["pointer-events"] = "none";
    body.style.overflow = "hidden";
  }
}
