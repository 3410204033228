import { Controller } from "@hotwired/stimulus";
import Inputmask from "inputmask";

export default class extends Controller {
  connect() {
    this.inputMaskCEP();
    this.inputMaskCredicCardNumber();
  }

  inputMaskCEP() {
    Inputmask("99999-999").mask(".cep");
  }

  inputMaskCredicCardNumber() {
    Inputmask("9999-9999-9999-9999").mask(".creditCardNumber");
    Inputmask("99/99").mask(".creditCardValidate");
    Inputmask("999").mask(".creditCardCVCValidate");
  }
}
