import { Controller } from "@hotwired/stimulus";
import crypto from "crypto";

export default class extends Controller {
  initialize() {
    this.couponOnFirstPurchase();
  }

  static targets = ["code", "discountValue", "discountKind", "couponOnFirstPurchase"];


  gearCode() {
    this.codeTarget.value = crypto.randomBytes(5).toString("hex").toUpperCase();
  }

  discountKindCheck() {
    const labelDiscountKind = this.discountValueTarget.querySelector(
      ".input-group .input-group-text"
    );

    const radioDiscountKindValue = this.discountKindTarget.querySelector(
      'input[name="coupon[discount_kind]"]'
    );

    const inputDiscountValue = this.discountValueTarget.querySelector(
      'input[name="coupon[discount_value]"]'
    );

    if (radioDiscountKindValue.checked) {
      labelDiscountKind.textContent = "%";
    } else {
      labelDiscountKind.textContent = "R$";
    }
  }
}
