import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs"

export default class extends Controller {  
  static targets = ["container", "buttonMobile", "buttonDesktop"]
  static values = {
    url: String,
    params: String,
  }

  connect() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // console.log(params)
    if(params.preview){
      if(params.preview == "mobile-preview") {
        this.selectButtonMobile()
      }
    }
  }

  selectButtonMobile() {
    this.containerTarget.classList.remove("desktop-preview")
    this.containerTarget.classList.add("mobile-preview")

    this.buttonDesktopTarget.classList.remove("active-button")
    this.buttonMobileTarget.classList.add("active-button")

    if (history.pushState) {
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?preview=mobile-preview';
      window.history.pushState({path:newurl},'',newurl);
    }
  }
  selectButtonDesktop() {
    this.containerTarget.classList.remove("mobile-preview")
    this.containerTarget.classList.add("desktop-preview")

    this.buttonMobileTarget.classList.remove("active-button")
    this.buttonDesktopTarget.classList.add("active-button")

    if (history.pushState) {
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?preview=desktop-preview';
      window.history.pushState({path:newurl},'',newurl);
    }
  }
}
