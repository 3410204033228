import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "checkoutOfferCountdown",
    "timerTextColor",
    "timerBgColor",
    "messageTextColor",
    "timerHour",
    "timerMinute",
    "redirectTimerActive",
    "featuredText",
    "timer",
    "hasPaymentRouter"
  ];

  connect() {
    this.checkTimerTextColor();
    this.checkTimerBgColor();
    this.checkTimerText();
    this.checkMessageTextColor();
    this.checkCountdown();
    this.checkCountdownActive();
    this.checkPaymentViaCard();
    this.checkPaymentViaPix();
    this.checkPaymentViaBankSlip();
    this.checkCardRedirect();
    this.checkPixRedirect();
    this.checkBankSlipRedirect();
    this.checkAccumulativeCardDiscount();
    this.checkAccumulativePixDiscount();
    this.checkAccumulativeBankSlipDiscount();
    this.timerCheck();
    this.featuredText();
    this.paymentRouterform();
  }

  // REDIRECT TIMER

  checkTimerBgColor() {
    $("#checkout_config_timer_bg_color").on("input", function () {
      $("#checkoutOfferCountdown").css("background-color", $(this).val());
    });
  }

  checkTimerTextColor() {
    $("#checkout_config_timer_text_color").on("input", function () {
      $("#countdown").css("color", $(this).val());
    });
  }

  checkMessageTextColor() {
    $("#checkout_config_message_text_color").on("input", function () {
      $("#message *").css("color", $(this).val());
    });
  }

  checkTimerText() {
    $("#checkout_config_featured_text_content").on("trix-change", function () {
      $("#message").html($(this).val());
    });
  }

  checkCountdown() {
    $("#checkout_config_timer_duration_minutes").on("input", function () {
      let hours = Math.floor($(this).val() / 60);
      let minutes = $(this).val() % 60;
      let seconds = 0;

      if (hours < 10) {
        hours = "0".concat(hours);
      }

      if (seconds < 10) {
        seconds = "0".concat(seconds);
      }

      if (minutes < 10) {
        minutes = "0".concat(minutes);
      }

      $("#timerHour").html(hours);
      $("#timerMinute").html(minutes);
      $("#timerSecond").html(seconds);
    });
  }

  checkCountdownActive() {
    const countdownActive = $(
      "[name='checkout_config[redirect_timer_active]']"
    );

    if (countdownActive.is(":checked")) {
      $("#timerParameters").removeClass("d-none");
    } else {
      $("#timerParameters").addClass("d-none");
    }
  }

  // PAYMENTS

  checkPaymentViaCard() {
    const paymentViaCard = $("[name='checkout_config[payment_via_card]']");
    if (paymentViaCard.is(":checked")) {
      $("#paymentViaCardParameters").removeClass("d-none");
    } else {
      $("#paymentViaCardParameters").addClass("d-none");
    }
  }

  checkPaymentViaPix() {
    const paymentViaPix = $("[name='checkout_config[payment_via_pix]']");

    // console.log(paymentViaPix);

    if (paymentViaPix.is(":checked")) {
      $("#paymentViaPixParameters").removeClass("d-none");
    } else {
      $("#paymentViaPixParameters").addClass("d-none");
    }
  }

  checkPaymentViaBankSlip() {
    const paymentViaBankSlip = $(
      "[name='checkout_config[payment_via_bank_slip]']"
    );

    // console.log(paymentViaBankSlip.is(":checked"));

    if (paymentViaBankSlip.is(":checked")) {
      $("#paymentViaBankSlipParameters").removeClass("d-none");
    } else {
      $("#paymentViaBankSlipParameters").addClass("d-none");
    }
  }

  // DISCOUNT
  checkAccumulativeCardDiscount() {
    const accumulativeCardDiscount = $(
      "[name='checkout_config[accumulative_card_discount]']"
    );

    // console.log(accumulativeCardDiscount.is(":checked"));

    if (accumulativeCardDiscount.is(":checked")) {
      $("#accumulativeCardDiscountParameters").removeClass("d-none");
    } else {
      $("#accumulativeCardDiscountParameters").addClass("d-none");
      $("#checkout_config_accumulative_card_discount_allow").prop(
        "checked",
        false
      );
      $("#checkout_config_discount_card").val(0);
    }
  }

  checkAccumulativePixDiscount() {
    const accumulativePixDiscount = $(
      "[name='checkout_config[accumulative_pix_discount]']"
    );

    // console.log(accumulativePixDiscount.is(":checked"));

    if (accumulativePixDiscount.is(":checked")) {
      $("#accumulativePixDiscountParameters").removeClass("d-none");
    } else {
      $("#accumulativePixDiscountParameters").addClass("d-none");
      $("#checkout_config_accumulative_pix_discount_allow").prop(
        "checked",
        false
      );
      $("#checkout_config_discount_pix").val(0);
    }
  }

  checkAccumulativeBankSlipDiscount() {
    const accumulativeBankSlipDiscount = $(
      "[name='checkout_config[accumulative_bank_slip_discount]']"
    );

    // console.log(accumulativeBankSlipDiscount.is(":checked"));

    if (accumulativeBankSlipDiscount.is(":checked")) {
      $("#accumulativeBankSlipDiscountParameters").removeClass("d-none");
    } else {
      $("#accumulativeBankSlipDiscountParameters").addClass("d-none");
      $("#checkout_config_accumulative_bank_slip_discount_allow").prop(
        "checked",
        false
      );
      $("#checkout_config_discount_bank_slip").val(0);
    }
  }

  // REDIRECT TIMER

  checkCardRedirect() {
    const cardRedirect = $("[name='checkout_config[card_redirect]']");

    // console.log(cardRedirect.is(":checked"));

    if (cardRedirect.is(":checked")) {
      $("#cardRedirectParameters").removeClass("d-none");
    } else {
      $("#cardRedirectParameters").addClass("d-none");
      $("#checkout_config_card_redirect_url").removeAttr('required')
    }
  }

  checkPixRedirect() {
    const pixRedirect = $("[name='checkout_config[pix_redirect]']");

    // console.log(pixRedirect.is(":checked"));

    if (pixRedirect.is(":checked")) {
      $("#pixRedirectParameters").removeClass("d-none");
    } else {
      $("#pixRedirectParameters").addClass("d-none");
      $("#checkout_config_pix_redirect_url").removeAttr('required')
    }
  }

  checkBankSlipRedirect() {
    const bankSlipRedirect = $("[name='checkout_config[bank_slip_redirect]']");

    // console.log(bankSlipRedirect.is(":checked"));

    if (bankSlipRedirect.is(":checked")) {
      $("#bankSlipRedirectParameters").removeClass("d-none");
    } else {
      $("#bankSlipRedirectParameters").addClass("d-none");
      $("#checkout_config_bank_slip_redirect_url").removeAttr('required')
    }
  }

  timerCheck() {
    let timerElement = document.getElementById('countdown');
    this.timerTarget.checked ? timerElement.classList.remove('d-none') : timerElement.classList.add('d-none');
  }

  featuredText() {
    let textElement = document.getElementById('message');
    this.featuredTextTarget.checked ? textElement.classList.remove('d-none') : textElement.classList.add('d-none');
  }

  paymentRouterform() {
    if (this.hasPaymentRouterTarget.checked) {
      $(".payment-houter-form").removeClass("d-none");
    } else {
      $(".payment-houter-form").addClass("d-none");
    }
  }
}
