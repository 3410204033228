import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  selectAll() {
    $(".select2-hidden-accessible > option").prop("selected", "selected");
    $(".select2-hidden-accessible").trigger("change");
  }

  selectNone() {
    $(".select2-hidden-accessible > option").prop("selected", "");
    $(".select2-hidden-accessible").trigger("change");
  }
}
