$(document).ready(function () {
  $("#checkoutCouponMessages .alert").fadeOut(3000);
});

$(".upsell_modal_product").on("change", function () {
  let hasSelectedIpsells = [...document.querySelectorAll(".upsell_modal_product")].some(element => ($(element).is(":checked")));
  hasSelectedIpsells ? $(".upsell_modal_product_remove").addClass('d-none') : $(".upsell_modal_product_remove").removeClass('d-none');

  $("#finalize_order").addClass("disabled");
  $("#close_checkout_modal").addClass("disabled");

  if ($(this).is(":checked")) {
    http: $.ajax({
      url: `/payments/${$(this).val()}/add_upsell`,
      type: "POST",
      dataType: "script",
    }).done(function () {
      $("#finalize_order").removeClass("disabled");
      $("#close_checkout_modal").removeClass("disabled");
    });
  } else {
    $.ajax({
      url: `/payments/${$(this).val()}/remove_cart_item_upsell`,
      type: "DELETE",
      dataType: "script",
    }).done(function () {
      $("#finalize_order").removeClass("disabled");
      $("#close_checkout_modal").removeClass("disabled");
    });
  }
});