import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "radio0",
    "radio1",
    "radio2",
    "label0",
    "label1",
    "label2",
    "image0",
    "image1",
    "image2",
    "urlDefaultInput",
    "checkoutCheck",
    "urlDefaultContainer",
  ];

  connect() {
    this.checkoutChecked();
    if (this.radio0Target.checked) {
      this.selectAdditionalFields0();
    }
    if (this.radio1Target.checked) {
      this.selectAdditionalFields1();
    }
    if (this.radio2Target.checked) {
      this.selectAdditionalFields2();
    }
    let labels = document.querySelectorAll(".label-landing-form");
    for (let label of labels) {
      label.classList.add("d-none");
    }
  }

  checkoutChecked() {
    // console.log(this.checkoutCheckTarget);
    const checkBillet = this.checkoutCheckTarget.checked;

    if (checkBillet == false || checkBillet == null) {
      this.urlDefaultContainerTarget.classList.add("d-none");
      this.urlDefaultInputTarget.value = "";
    } else {
      this.urlDefaultContainerTarget.classList.remove("d-none");
    }

    // console.log(this.checkBilletTarget.checked);
  }

  selectAdditionalFields0() {
    this.image0Target.classList.add("isActive");
    this.image1Target.classList.remove("isActive");
    this.image2Target.classList.remove("isActive");
  }
  selectAdditionalFields1() {
    this.image0Target.classList.remove("isActive");
    this.image1Target.classList.add("isActive");
    this.image2Target.classList.remove("isActive");
  }
  selectAdditionalFields2() {
    this.image0Target.classList.remove("isActive");
    this.image1Target.classList.remove("isActive");
    this.image2Target.classList.add("isActive");
  }
}
