import consumer from "./consumer";

const elementStartSocket = document.querySelector("#start_websocket");

if (elementStartSocket) {
  consumer.subscriptions.create(
    {
      channel: "InvoiceChannel",
      invoice_id: elementStartSocket.dataset.id,
    },
    {
      connected() {},

      received(data) {
        console.log("TESTE");
        console.log(data);
        if (data?.success) {
          // this.perform("pixel_sended", {
          //   order_id: elementStartSocket.dataset.id,
          // });
        }
      },
    }
  );
}
