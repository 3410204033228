export default function (
  integrationDataJson,
  fieldsSuffix,
  fieldsSuffixUnderscore
) {
  if ($("#paymentKindCreditCart").hasClass("show")) {
    if (integrationDataJson.is_active) {
      let pagSeguroPublicKey = integrationDataJson.public_key;
      let cardDigits = $(".credit_card_number_input").val().replace(/\s+/g, "");
      let cardholderName = $(".credit_card_titular_name_input").val();
      // let paymentCardExpiry = $('.credit_card_validate_input').val();
      let paymentCardExpiry = `${$(".month_validate_input").val()}/${$(
        ".year_validate_input"
      ).val()}`;
      let securityCode = $(".credit_card_security_code_input").val();
      let paymentCardExpirySplit = paymentCardExpiry.split("/"); // Variavel auxiliar
      let cardExpirationMonth = paymentCardExpirySplit[0];
      let cardExpirationYear = paymentCardExpirySplit[1];
      if (cardExpirationYear.length === 2) {
        cardExpirationYear = `20${paymentCardExpirySplit[1]}`;
      }

      // Iniciando o SDK do pag seguro
      const card_object = PagSeguro.encryptCard({
        publicKey: pagSeguroPublicKey,
        holder: cardholderName,
        number: cardDigits,
        expMonth: cardExpirationMonth,
        expYear: cardExpirationYear,
        securityCode: securityCode,
      });
      if (card_object.hasErrors) {
        errorFlow(card_object.errors, fieldsSuffix, fieldsSuffixUnderscore);
      } else {
        let gateway_integrations_data_div = $("#gateway_integrations_data");
        let additional_infos_div = $("#card_form_additional_infos");
        let pag_seguro_token_div = $(
          `#${fieldsSuffixUnderscore}_additional_infos_pag_seguro_token`
        );

        if (pag_seguro_token_div.length) {
          pag_seguro_token_div.remove();
        }
        additional_infos_div.append(
          "<input " +
          'type="hidden" ' +
          `name="${fieldsSuffix}[additional_infos][pag_seguro][token]" ` +
          `id="${fieldsSuffixUnderscore}_additional_infos_pag_seguro_token" ` +
          `value="${card_object.encryptedCard}" >`
        );

        let completed_json = gateway_integrations_data_div.data("completed");
        completed_json.pagSeguro = true;
        gateway_integrations_data_div
          .data("completed", completed_json)
          .trigger("click");
      }
    }
  }
}

function errorFlow(errors, fieldsSuffix, fieldsSuffixUnderscore) {
  let errors_codes = errors.map(function (error) {
    return error.code;
  });
  let errors_join = errors_codes.join(";");
  let gateway_integrations_data_div = $("#gateway_integrations_data");
  let additional_infos_div = $("#card_form_additional_infos");
  let pag_seguro_error_div = $(
    `#${fieldsSuffixUnderscore}_additional_infos_pag_seguro_error`
  );

  if (pag_seguro_error_div.length) {
    pag_seguro_error_div.remove();
  }
  additional_infos_div.append(
    "<input " +
    'type="hidden" ' +
    `name="${fieldsSuffix}[additional_infos][pag_seguro][errors]" ` +
    `id="${fieldsSuffixUnderscore}_additional_infos_pag_seguro_errors" ` +
    `value="${errors_join}" >`
  );

  let completed_json = gateway_integrations_data_div.data("completed");
  completed_json.pagSeguro = true;
  gateway_integrations_data_div
    .data("completed", completed_json)
    .trigger("click");
}
