export default function (
  integrationDataJson,
  fieldsSuffix,
  fieldsSuffixUnderscore
) {
  if ($("#paymentKindCreditCart").hasClass("show")) {
    if (integrationDataJson.is_active) {
      let mercadoPagoPublicKey = integrationDataJson.public_key;
      let cardDigits = $(".credit_card_number_input").val().replace(/\s+/g, "");
      let cardholderName = $(".credit_card_titular_name_input").val();
      // let paymentCardExpiry = $(".credit_card_validate_input").val();
      let paymentCardExpiry = `${$(".month_validate_input").val()}/${$(
        ".year_validate_input"
      ).val()}`;
      let securityCode = $(".credit_card_security_code_input").val();
      let identificationNumber = $(".credit_card_titular_document_input")
        .val()
        .replace(/\D+/g, "");
      let identificationType;

      if (identificationNumber.length === 11) {
        identificationType = "CPF";
      } else {
        // Esse else não está sendo utilizado.
        identificationType = "CNPJ";
      }

      let cardSixFirstDigits = cardDigits.slice(0, 6);
      let paymentCardExpirySplit = paymentCardExpiry.split("/"); // Variavel auxiliar
      let cardExpirationMonth = paymentCardExpirySplit[0];
      let cardExpirationYear = paymentCardExpirySplit[1];
      if (cardExpirationYear.length === 2) {
        cardExpirationYear = `20${paymentCardExpirySplit[1]}`;
      }

      // Iniciando o SDK do mercado pago
      const mp = new MercadoPago(mercadoPagoPublicKey);
      console.log("MERCADO PAGO", mp);
      // Buscando os métodos de pagamento daquele cartão
      mp.getPaymentMethods({ bin: cardSixFirstDigits })
        .then((response) => {
          console.log(response);
          let paymentMethodId = response["results"][0]["id"];
          let paymentCardIssuerId = response["results"][0]["issuer"]["id"];
          mp.createCardToken({
            cardNumber: cardDigits,
            cardholderName: cardholderName,
            cardExpirationMonth: cardExpirationMonth,
            cardExpirationYear: cardExpirationYear,
            securityCode: securityCode,
            identificationType: identificationType,
            identificationNumber: identificationNumber,
          })
            .then((response) => {
              let gateway_integrations_data_div = $(
                "#gateway_integrations_data"
              );
              let additional_infos_div = $("#card_form_additional_infos");
              let mercado_pago_issuer_id_div = $(
                `#${fieldsSuffixUnderscore}_additional_infos_mercado_pago_issuer_id`
              );
              let mercado_pago_payment_method_id_div = $(
                `#${fieldsSuffixUnderscore}_additional_infos_mercado_pago_payment_method_id`
              );
              let mercado_pago_token_div = $(
                `#${fieldsSuffixUnderscore}_additional_infos_mercado_pago_token`
              );

              if (mercado_pago_issuer_id_div.length) {
                mercado_pago_issuer_id_div.remove();
              }
              additional_infos_div.append(
                "<input " +
                'type="hidden" ' +
                `name="${fieldsSuffix}[additional_infos][mercado_pago][issuer_id]" ` +
                `id="${fieldsSuffixUnderscore}_additional_infos_mercado_pago_issuer_id" ` +
                `value="${paymentCardIssuerId}" >`
              );

              if (mercado_pago_payment_method_id_div.length) {
                mercado_pago_payment_method_id_div.remove();
              }
              additional_infos_div.append(
                "<input " +
                'type="hidden" ' +
                `name="${fieldsSuffix}[additional_infos][mercado_pago][payment_method_id]" ` +
                `id="${fieldsSuffixUnderscore}_additional_infos_mercado_pago_payment_method_id" ` +
                `value="${paymentMethodId}" >`
              );

              if (mercado_pago_token_div.length) {
                mercado_pago_token_div.remove();
              }
              additional_infos_div.append(
                "<input " +
                'type="hidden" ' +
                `name="${fieldsSuffix}[additional_infos][mercado_pago][token]" ` +
                `id="${fieldsSuffixUnderscore}_additional_infos_mercado_pago_token" ` +
                `value="${response["id"]}" >`
              );

              let completed_json =
                gateway_integrations_data_div.data("completed");
              completed_json.mercadoPago = true;
              gateway_integrations_data_div
                .data("completed", completed_json)
                .trigger("click");
            })
            .catch((error) => {
              errorFlow(error, fieldsSuffix, fieldsSuffixUnderscore);
            });
        })
        .catch((error) => {
          console.log("ERROR");
          errorFlow(error, fieldsSuffix, fieldsSuffixUnderscore);
        });
    }
  }
}

function errorFlow(error, fieldsSuffix, fieldsSuffixUnderscore) {
  let gateway_integrations_data_div = $("#gateway_integrations_data");
  let additional_infos_div = $("#card_form_additional_infos");
  let mercado_pago_error_div = $(
    `#${fieldsSuffixUnderscore}_additional_infos_mercado_pago_error`
  );

  if (mercado_pago_error_div.length) {
    mercado_pago_error_div.remove();
  }
  additional_infos_div.append(
    "<input " +
    'type="hidden" ' +
    `name="${fieldsSuffix}[additional_infos][mercado_pago][error]" ` +
    `id="${fieldsSuffixUnderscore}_additional_infos_mercado_pago_error" ` +
    `value="${error}" >`
  );

  let completed_json = gateway_integrations_data_div.data("completed");
  completed_json.mercadoPago = true;
  gateway_integrations_data_div
    .data("completed", completed_json)
    .trigger("click");
}
