import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selectEl", "timeOperationRadio", "monthlyIncomeContainer"];
  
  selectAndRedirect() {
    window.location = this.selectElTarget.value; // redirect

    return false;
  }

  timeOperationRadioSelect() {
    if(event.target.value != 'im_starting') {
      this.monthlyIncomeContainerTarget.classList.remove('d-none')
    }else{
      this.monthlyIncomeContainerTarget.classList.add('d-none')
    }
  }
}
