import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "tagManagerId",
    "connectionMethod",
    "accessToken",
    "service",
    "checkBillet",
    "checkPixContainer",
    "checkPix",
    "checkPixPending",
    "checkPixPurchase",
    "eventBilletCustomize",
    "eventBilletCustomizeField",
    "eventBilletCustomizeData",
    "tagManagerDomainConversion",
    "pageHelpFacebook",
    "pageHelpGoogle",
    "pageHelpTiktok",
    "pageHelpKwai",
    "labelCode",
    "code",
  ];

  connect() {
    this.connectionMethodCheck();
    this.serviceCheck();
    this.checkBilletCheck();
    // this.checkPixCheck();
    this.eventBilletCustomizeFieldCheck();
  }

  connectionMethodCheck() {
    const connectionMethod = this.connectionMethodTarget.querySelector(
      'input[name="pixel[connection_method]"]:checked'
    );

    if (
      connectionMethod?.value == "traditional" ||
      connectionMethod?.value == null
    ) {
      this.accessTokenTarget.classList.add("d-none");
    } else {
      this.accessTokenTarget.classList.remove("d-none");
    }
    // console.log(connectionMethod);
  }

  serviceCheck() {
    const service = this.serviceTarget.querySelector(
      'input[name="pixel[service]"]:checked'
    );

    const connectionMethod = this.connectionMethodTarget.querySelector(
      'input[name="pixel[connection_method]"]:checked'
    );

    if (service?.value == "google") {
      this.tagManagerIdTarget.classList.remove("d-none");
      this.pageHelpGoogleTarget.classList.remove("d-none");
      this.tagManagerDomainConversionTarget.classList.remove("d-none");
      this.labelCodeTarget.innerHTML = "ID de conversão";
      this.accessTokenTarget.classList.add("d-none");
    } else {
      this.tagManagerIdTarget.classList.add("d-none");
      this.pageHelpGoogleTarget.classList.add("d-none");
      this.labelCodeTarget.innerHTML = "ID do pixel";
    }

    if (service?.value == "facebook") {
      this.accessTokenTarget.classList.remove("d-none");
      if (connectionMethod?.value == "traditional") {
        this.accessTokenTarget.classList.add("d-none");
      }
      // console.log("TESTE", service);
      console.log(this.pageHelpFacebookTarget.classList);
      this.pageHelpFacebookTarget.classList.remove("d-none");
      $("#pageHelpFacebook").removeClass("d-none");
      this.connectionMethodTarget.classList.remove("d-none");
      this.tagManagerDomainConversionTarget.classList.add("d-none");
    } else {
      // this.checkPixTarget.classList.add("d-none");
      this.accessTokenTarget.classList.add("d-none");
      this.tagManagerDomainConversionTarget.classList.remove("d-none");
      $("#pageHelpFacebook").addClass("d-none");
      this.pageHelpFacebookTarget.classList.add("d-none");
      this.connectionMethodTarget.classList.add("d-none");
    }

    if (service?.value == "tiktok") {
      this.accessTokenTarget.classList.add("d-none");
      this.pageHelpTiktokTarget.classList.remove("d-none");
      this.tagManagerDomainConversionTarget.classList.add("d-none");
    } else {
      this.pageHelpTiktokTarget.classList.add("d-none");
    }

    if (service?.value == "kwai") {
      this.pageHelpKwaiTarget.classList.remove("d-none");
      this.tagManagerDomainConversionTarget.classList.add("d-none");
    } else {
      this.pageHelpKwaiTarget.classList.add("d-none");
    }

    if (service?.value == "taboola") {
      this.tagManagerDomainConversionTarget.classList.add("d-none");
    }

    if (service?.value == "gtm") {
      this.codeTarget.classList.add("d-none");
      this.tagManagerDomainConversionTarget.classList.add("d-none");
      this.tagManagerIdTarget.classList.remove("d-none");
      document.getElementsByName("pixel[code]")[0].required = false;
      document.getElementsByName("pixel[tag_manager_id]")[0].placeholder = "GTM-XXXXXXXX";
    } else {
      this.codeTarget.classList.remove("d-none");
      this.tagManagerDomainConversionTarget.classList.remove("d-none");
      this.tagManagerIdTarget.classList.add("d-none");
      document.getElementsByName("pixel[code]")[0].required = true;
      document.getElementsByName("pixel[tag_manager_id]")[0].placeholder = "";
    }
  }

  checkBilletCheck() {
    const checkBillet = this.checkBilletTarget.checked;

    if (checkBillet == false || checkBillet == null) {
      this.eventBilletCustomizeTarget.classList.add("d-none");
      this.eventBilletCustomizeDataTarget.classList.add("d-none");
      this.eventBilletCustomizeFieldTarget.checked = false;
    } else {
      this.eventBilletCustomizeTarget.classList.remove("d-none");
    }

    // console.log(this.checkBilletTarget.checked);
  }

  checkPixCheck() {
    const checkPix = this.checkPixTarget.checked;

    if (checkPix == false || checkPix == null) {
      // this.checkPixPendingTarget.classList.add("d-none");
      this.checkPixPurchaseTarget.classList.add("d-none");
      document.getElementById("pixel_has_pix_pending").checked = false;
      document.getElementById("pixel_check_pix_only_after_payment").checked = false;
    } else {
      // this.checkPixPendingTarget.classList.remove("d-none");
      this.checkPixPurchaseTarget.classList.remove("d-none");
    }

    // console.log(this.checkBilletTarget.checked);
  }

  eventBilletCustomizeFieldCheck() {
    const eventBilletCustomizeField =
      this.eventBilletCustomizeFieldTarget.checked;

    if (
      eventBilletCustomizeField == false ||
      eventBilletCustomizeField == null
    ) {
      this.eventBilletCustomizeDataTarget.classList.add("d-none");
      document.getElementById("pixel_event_name").value = "";
      document.getElementById("pixel_event_type").value = "";
    } else {
      this.eventBilletCustomizeDataTarget.classList.remove("d-none");
    }

    // console.log(this.eventBilletCustomizeFieldTarget.checked);
  }
}