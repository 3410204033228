$(document).ready(function () {
  $('input[name="card_form[payment_kind]"]').change(function(e) {
    if(e.target.value == '2') {
      $('#card_form_credit_card_number').val("")
      $('#card_form_credit_card_titular_name').val("")
      $('#card_form_credit_card_validate').val("")
      $('#card_form_credit_card_security_code').val("")
      $('#card_form_credit_card_titular_document').val("")
      $('#card_form_credit_card_same_buyer').prop("checked", false)
    }
  })
})