import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nextButton", "streetField", "cpfField"];

  connect() {
    this.checkForm();
  }

  checkForm() {
    if (
      this.streetFieldTarget.value.length == 0 &&
      this.cpfFieldTarget.value.length == 0
    ) {
      this.nextButtonTarget.classList.add("disabled");
    } else {
      this.nextButtonTarget.classList.remove("disabled");
    }
  }
}
