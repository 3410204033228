import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs"

export default class extends Controller {  
  static targets = ["container", "buttonMobile", "buttonDesktop"]
  static values = {
    url: String,
    params: String,
  }

  connect() {
    const iframeExist = document.getElementById("iframe-preview")
    if(!iframeExist){
      var iframe = document.createElement("iframe");
      iframe.id = "iframe-preview";
      iframe.src = this.urlValue;
      iframe.width = "100%";
      iframe.height = "100%";
  
      this.containerTarget.appendChild(iframe)
    }

  }
}
