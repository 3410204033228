import { Controller } from "@hotwired/stimulus";
import { refreshVMasker } from "../src/admin/initializers/vmasker";

// Connects to data-controller="registration"
export default class extends Controller {
  static targets = ["hasZoop", "zoopFields"];

  initialize() {
    console.log(this.hasZoopTarget);
    if (this.hasZoopTarget.checked) {
      this.zoopFieldsTarget.classList.remove("d-none");
    } else {
      this.zoopFieldsTarget.classList.add("d-none");
    }
  }

  showFieldsZoop(e) {
    if (e.target.checked) {
      this.zoopFieldsTarget.classList.remove("d-none");
    } else {
      this.zoopFieldsTarget.classList.add("d-none");
    }
  }
}
