import { getCardType } from "../card";

export default function () {
  if ($("#paymentKindCreditCart").hasClass("show")) {
    let cardDigits = $(".credit_card_number_input").val().replace(/\s+/g, "");
    if (cardDigits.length > 14) {
      $.get(
        `/payments/get_installments?bin=${cardDigits.slice(0, 8)}`,
        {},
        function (data) {
          if (data != 0) {
            $("#brand_card_form").val(getCardType(cardDigits));
            $(".payment_installments_input").attr("disabled", false);
            $(".payment_installments_input").val("");
            $(".payment_installments_input").html("");
            Object.entries(data.installments).forEach(([key, value]) => {
              let newOption = new Option(value[0], key, false, value[1]);
              // Append it to the select
              $(".payment_installments_input").append(newOption);
            });
          } else {
            // console.log("error");
          }
        }
      );
    }
  }
}
