import { Controller } from "@hotwired/stimulus";
import { refreshVMasker } from "../src/admin/initializers/vmasker";

// Connects to data-controller="registration"
export default class extends Controller {
  static targets = [
    "verifyHide",
    "label0",
    "label1",
    "additionalFields",
    "documentLabel",
    "documentField",
    "documentLabelCnpj",
    "documentFieldCnpj",
  ];

  initialize() {
    this.hideFields();
    let labels = document.querySelectorAll(".form-check-label");
    for (let label of labels) {
      label.classList.add("d-none");
    }

    if (this.verifyHideTarget.dataset.value) {
      if (this.verifyHideTarget.dataset.kind == "cnpj") {
        this.documentFieldTarget.value = "";
        this.showAdditionalFields1();
      } else {
        this.documentFieldCnpjTarget.value = "";
        this.showAdditionalFields0();
      }
    } else {
      if (this.verifyHideTarget.dataset.kind == "cnpj") {
        this.documentFieldTarget.value = "";
      } else {
        this.documentFieldCnpjTarget.value = "";
      }
    }
  }

  hideFields() {
    for (let fields of this.additionalFieldsTargets) {
      fields.disabled = fields.hidden = true;
      this.label0Target.classList.remove("active");
      this.label1Target.classList.remove("active");
      this.documentFieldTarget.hidden = true;
      this.documentLabelTarget.hidden = true;
      this.documentFieldCnpjTarget.hidden = true;
      this.documentLabelCnpjTarget.hidden = true;

      refreshVMasker();
    }
  }

  showAdditionalFields0() {
    for (let fields of this.additionalFieldsTargets) {
      fields.disabled = fields.hidden = fields.dataset.type != 0;
    }
    this.documentLabelTarget.hidden = false;
    this.documentFieldTarget.hidden = false;
    this.documentFieldTarget.disabled = false;
    this.documentLabelCnpjTarget.hidden = true;
    this.documentFieldCnpjTarget.hidden = true;
    this.documentFieldCnpjTarget.disabled = true;

    this.label0Target.classList.add("active");

    this.label1Target.classList.remove("active");

    refreshVMasker();
    $("#seller_phone_attributes_number").attr("disabled", false);
    if (
      $(".invalid-feedback") &&
      $(".invalid-feedback")
        .html()
        .includes(
          "C.N.P.J. inválido! Verifique e digite o número corretamente."
        )
    ) {
      $(".invalid-feedback").html(
        "C.P.F. inválido! Verifique e digite o número corretamente."
      );
    }
    // this.documentLabelTarget.innerHTML = "CPF";
    // this.documentFieldTarget.placeholder = "CPF";
    // this.documentFieldTarget.classList.remove("cnpj");
    // this.documentFieldTarget.classList.add("cpf");
  }

  showAdditionalFields1() {
    for (let fields of this.additionalFieldsTargets) {
      fields.disabled = fields.hidden = fields.dataset.type != 1;
    }
    this.documentLabelTarget.hidden = true;
    this.documentFieldTarget.hidden = true;
    this.documentFieldTarget.disabled = true;
    this.documentLabelCnpjTarget.hidden = false;
    this.documentFieldCnpjTarget.hidden = false;
    this.documentFieldCnpjTarget.disabled = false;
    $("#seller_phone_attributes_number").attr("disabled", true);
    // this.documentLabelTarget.hidden = false;
    // this.documentLabelTarget.innerHTML = "CNPJ";
    // this.documentFieldTarget.placeholder = "CNPJ";
    this.label0Target.classList.remove("active");
    this.label1Target.classList.add("active");

    refreshVMasker();
    if (
      $(".invalid-feedback") &&
      $(".invalid-feedback")
        .html()
        .includes("C.P.F. inválido! Verifique e digite o número corretamente.")
    ) {
      $(".invalid-feedback").html(
        "C.N.P.J. inválido! Verifique e digite o número corretamente."
      );
    }
    // this.documentFieldTarget.hidden = false;
    // this.documentFieldTarget.classList.remove("cpf");
    // this.documentFieldTarget.classList.add("cnpj");
  }
}
