import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "radio1",
    "radio2",
    "label1",
    "label2",
    "image1",
    "image2",
    "urlDefaultInput",
    "urlDefaultContainer",
  ];

  connect() {
    // console.log(this.radio1Target.checked);
    if (this.radio1Target.checked) {
      this.selectAdditionalFields1();
    }
    if (this.radio2Target.checked) {
      this.selectAdditionalFields2();
    }
    let labels = document.querySelectorAll(".label-landing-form");
    for (let label of labels) {
      label.classList.add("d-none");
    }
  }

  selectAdditionalFields1() {
    this.image1Target.classList.add("isActive");
    this.image2Target.classList.remove("isActive");
  }
  selectAdditionalFields2() {
    // console.log("TESTE");
    // console.log(this.image1Target.classList);
    this.image1Target.classList.remove("isActive");
    this.image2Target.classList.add("isActive");
  }
}
