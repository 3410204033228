import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "selectEl",
    "selectProduct",
    "selectKind",
    "containerValue",
    "containerProducts",
  ];

  connect() {
    self = this;
    $("#order_bump_product_id").on("change", function (e) {
      //console.log($("#order_bump_product_id").val())
      self.getVariations();
    });
    $("#order_bump_kind").on("change", function (e) {
      self.changeKindField();
    });

    $("#selectAll").on("click", (e) => self.getAllSameProducts());
    $("#selectNone").on("click", (e) => self.selectNone());

    // this.getVariations();
    this.changeKindField();
  }

  changeKindField() {
    if (this.selectKindTarget.value == "value") {
      $("#order_bump_product_ids").val("");
      this.containerValueTarget.classList.remove("d-none");
      this.containerProductsTarget.classList.add("d-none");
    } else if (this.selectKindTarget.value == "product") {
      this.containerValueTarget.classList.add("d-none");
      this.containerProductsTarget.classList.remove("d-none");
    } else {
      $("#order_bump_product_ids").val();
      $("#order_bump_product_ids").val(null);
      this.containerValueTarget.classList.add("d-none");
      this.containerProductsTarget.classList.add("d-none");
    }
  }

  getVariations() {
    // console.log(this);

    // console.log(this.selectProductTarget.value);
    $.get(
      "/list/variations/" + this.selectProductTarget.value,
      function (data) {
        self.selectNone();
        //console.log("list/variations");
        //console.log(data);
        let x;
        $("#order_bump_variation_id").html("");
        $("#order_bump_variation_id").append(
          "<option value> Selecione a variação </option >"
        );
        for (x in data) {
          let option =
            '<option value ="' +
            data[x].id +
            '">' +
            data[x].name +
            "</option >";
          $("#order_bump_variation_id").append(option);
        }
        self.getSameProducts();
      }
    );
  }

  getAllSameProducts() {
    $.get(
      "/products/" + this.selectProductTarget.value + "/get_same_types",
      function (data) {
        //console.log("list/products/same_types");
        //console.log(data);
        let x;
        $("#order_bump_product_ids").html("");
        for (x in data) {
          let option =
            '<option value ="' +
            data[x].id +
            '">' +
            data[x].name +
            "</option >";
          $("#order_bump_product_ids").append(option);
        }
        $("#order_bump_product_ids > option").prop("selected", "selected");
      }
    );
  }

  selectNone() {
    $("#order_bump_product_ids > option").prop("selected", "");
    $("#order_bump_product_ids").trigger("change");
    self.getSameProducts();
  }

  getSameProducts() {
    $.get(
      "/products/" + this.selectProductTarget.value + "/get_same_types",
      function (data) {
        //console.log("list/products/same_types");
        //console.log(data);
        let x;
        $("#order_bump_product_ids").html("");
        for (x in data) {
          let option =
            '<option value ="' +
            data[x].id +
            '">' +
            data[x].name +
            "</option >";
          $("#order_bump_product_ids").append(option);
        }
      }
    );
  }
}
