import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [
    "activeGamefication",
    "activeGameficationRanking",
    "activeGameficationStamps"
  ]

  connect() {
    this.applyStyle();
    this.setup();
  }

  applyStyle() {
    const child = document.querySelector('#timeline')
    const parent = child.parentElement;

    parent.style.backgroundColor = 'white'
    parent.parentElement.style.backgroundColor = 'white';
    parent.parentElement.style.margin = '0px 0px 0px 0px';
    document.querySelector('.page-header').style.backgroundColor = 'white';
  }


  setup() {
    var hasMedals = document.querySelectorAll(".has-medal")
    var lastHasMedal = hasMedals[hasMedals.length - 1]
    var lastMedalReward = lastHasMedal.getElementsByClassName("percentage_total_paid_orders")[0].value;

    lastHasMedal.style.removeProperty("background-color")
    lastHasMedal.style.setProperty("background-color", "#bababa")
    lastHasMedal.classList.add('length-process-via-js');
    lastHasMedal.style.setProperty('--pseudo-height', lastMedalReward);
  }


  async activeReward() {
    var toggle = this.activeGameficationTarget.checked;
    var result = window.confirm("Você deseja realizar essa operação?")
    if (result) {
      let request = await fetch(`clients/active_store_rewards?active=${toggle}`, { method: "POST" })
    } else {
      this.activeGameficationTarget.checked = !toggle
    }
  }

  async activeRanking() {
    var toggle = this.activeGameficationRankingTarget.checked;
    var result = window.confirm("Você deseja realizar essa operação?")
    if (result) {
      await fetch(`clients/active_ranking_sellers?active=${toggle}`, { method: "POST" })
    } else {
      this.activeGameficationRankingTarget.checked = !toggle
    }
  }

  async activeStamps() {
    var toggle = this.activeGameficationStampsTarget.checked;
    var result = window.confirm("Você deseja realizar essa operação?")
    if (result) {
      await fetch(`clients/active_stamps_store?active=${toggle}`, { method: "POST" })
    } else {
      this.activeGameficationRankingTarget.checked = !toggle
    }
  }
}
