export default function (paymentKind) {
  $(".next").addClass("disabled");
  $("#finalize_order_button").addClass("disabled");
  $.ajax({
    url: `/payments/change_payment_kind?payment_kind=${paymentKind}`,
    type: "GET",
    dataType: "script",
  }).done(function () {
    $(".next").removeClass("disabled");
    $("#finalize_order_button").removeClass("disabled");
  });
}
