import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "selectEl",
    "selectProduct",
    "containerValue",
    "containerProducts",
  ];

  connect() {
    self = this;
    $("#upsell_product_offer_id").on("change", function (e) {
      self.getVariations();
    });
    $("input[type=radio][name='upsell[kind]']").on("change", function (e) {
      if (this.value === 'checkout') {
        self.enableBackgroundColorInput();
      }
      else if (this.value === 'on_finish') {
        self.disableBackgroundColorInput();
      } else {
        self.enableBackgroundColorInput();
      }
    });
  }

  disableBackgroundColorInput() {
    $("input[type=color][name='upsell[background_color]']").prop('disabled', true)
    $("#upsellBackgroundColor").addClass('d-none')
  }

  enableBackgroundColorInput() {
    $("input[type=color][name='upsell[background_color]']").prop('disabled', false)
    $("#upsellBackgroundColor").removeClass('d-none')
  }

  getVariations() {
    console.log(this);

    // console.log(this.selectProductTarget.value);
    $.get(
      "/list/variations/" + this.selectProductTarget.value,
      function (data) {
        console.log("list/variations");
        console.log(data);
        let x;
        $("#upsell_variation_offer_id").html("");
        $("#upsell_variation_offer_id").append(
          "<option value> Selecione a variação </option >"
        );
        for (x in data) {
          let option =
            '<option value ="' +
            data[x].id +
            '">' +
            data[x].name +
            "</option >";
          $("#upsell_variation_offer_id").append(option);
        }
      }
    );

    $.get(
      "/products/" + this.selectProductTarget.value + "/get_same_types",
      function (data) {
        console.log("list/products/same_types");
        console.log(data);
        let x;
        $("#upsell_product_offer_ids").html("");
        for (x in data) {
          let option =
            '<option value ="' +
            data[x].id +
            '">' +
            data[x].name +
            "</option >";
          $("#upsell_product_offer_ids").append(option);
        }
      }
    );
  }
}
