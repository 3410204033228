import { Controller } from "@hotwired/stimulus";
import _ from 'lodash';

export default class extends Controller {
  initialize() {
    this.lastEvent = null;
    this.throttle = new Date();
  }

  async eventHandler(event) {
    if (this.lastEvent === event.type && new Date() - this.throttle < 1000) return;

    this.lastEvent = event.type;
    this.throttle = new Date();

    if (event.type === 'begin_checkout') {
      localStorage.removeItem('datalayer');
      localStorage.removeItem('sign_up_lock');
      localStorage.removeItem('purchase_lock');
    }

    if (event.type === 'purchase' && localStorage.getItem('purchase_lock') === 'yes') return;
    if (event.type === 'sign_up' && localStorage.getItem('sign_up_lock') === 'yes') return;

    let queryString = _.merge(event.detail, { event: event.type })
    let request = await fetch('/api/v1/datalayer?' + new URLSearchParams(queryString).toString());
    let newData = _.merge(JSON.parse(localStorage.getItem('datalayer')), await request.json());

    if (newData.error) {
      console.error(newData);
      return;
    }

    localStorage.setItem('datalayer', JSON.stringify(newData));
    if (event.type === 'sign_up') localStorage.setItem('sign_up_lock', 'yes');
    if (event.type === 'purchase') localStorage.setItem('purchase_lock', 'yes');

    window.dataLayer.push({
      event: event.type,
      ...newData,
    });
  }
}
