import mercadoPagoInitializer from "./mercadoPagoInitializer";
import pagSeguroInitializer from "./pagSeguroInitializer";
import getInstallments from "./getInstallments";
import changePaymentKind from "./changePaymentKind";

let timeoutIntegration = null;
function integrationsInitializers() {
  return new Object({
    mercadoPago: mercadoPagoInitializer,
    pagSeguro: pagSeguroInitializer,
  });
}

function isPresent(string) {
  return typeof string === "string" && string.trim() !== "";
}

function executeIntegrationsInitializers() {
  let integrationsInitializersObject = integrationsInitializers();
  let gateway_integrations_data_field = $("#gateway_integrations_data");
  let data_json = gateway_integrations_data_field.data("json");
  let checkoutType = gateway_integrations_data_field.data("checkout");
  let fieldsSuffix = null;
  let fieldsSuffixUnderscore = null;
  if (checkoutType === "oneStep") {
    fieldsSuffix = "customer[orders]";
    fieldsSuffixUnderscore = "customer_orders";
  } else {
    fieldsSuffix = "card_form";
    fieldsSuffixUnderscore = "card_form";
  }

  for (let integration in data_json) {
    integrationsInitializersObject[integration](
      data_json[integration],
      fieldsSuffix,
      fieldsSuffixUnderscore
    );
  }
}

function initializeIntegrationsTimeout() {
  timeoutIntegration = setTimeout(function () {
    let completed_json = $("#gateway_integrations_data").data("completed");
    if (!Object.values(completed_json).every((v) => v === true)) {
      $("#button-submit-form-checkout").click();
    }
  }, 4000);
}

export const initializeListeners = () => {
  if (
    $(".credit_card_titular_document_input").length &&
    $(".customer_cpf_input").length
  ) {
    $(".customer_cpf_input").on("blur", function () {
      $(".credit_card_titular_document_input").val($(this).val());
    });
  }

  $("#headingPaymentKindCreditCart").on("click", function () {
    if (!$(".credit_card_payment_kind").prop("checked")) {
      changePaymentKind("card");
    }
    $(".credit_card_payment_kind").prop("checked", true);
  });

  $("#headingPaymentKindTicket").on("click", function () {
    if (!$(".ticket_payment_kind").prop("checked")) {
      changePaymentKind("bank_slip");
    }
    $(".ticket_payment_kind").prop("checked", true);
  });

  $("#headingPaymentKindPix").on("click", function () {
    if (!$(".pix_payment_kind").prop("checked")) {
      changePaymentKind("pix");
    }
    $(".pix_payment_kind").prop("checked", true);
  });

  $(".credit_card_payment_kind").on("change", function () {
    changePaymentKind("card");
  });

  $(".ticket_payment_kind").on("change", function () {
    changePaymentKind("bank_slip");
  });

  $(".pix_payment_kind").on("change", function () {
    changePaymentKind("pix");
  });

  if ($(".pix_payment_kind").prop('checked') == true) {
    changePaymentKind("pix");
  }
  else if ($(".ticket_payment_kind").prop('checked') == true) {
    changePaymentKind("bank_slip");
  }
  else if ($(".credit_card_payment_kind").prop('checked') == true) {
    changePaymentKind("card");
  }

  window.getInstallments = getInstallments;

  let gateway_integrations_data_div = $("#gateway_integrations_data");
  let gateway_integrations_data_div_exist =
    gateway_integrations_data_div.length > 0;

  if ($(".credit_card_number_input").val() != "") {
    getInstallments();
  }

  $(".credit_card_number_input").on("blur", function () {
    getInstallments();
  });

  if (gateway_integrations_data_div_exist) {
    $("#finalize_order_button").on("click", function () {
      $("#finalize_order_button").attr("disabled", true);
      $("#finalize_order_button").html(
        '<span class="mdi mdi-spin mdi-loading"></span><span class="mx-2">Aguarde...</span>'
      );

      if ($("#paymentKindCreditCart").hasClass("show")) {
        if ($(".credit_card_titular_document_input").val() == "") {
          $(".credit_card_titular_document_input").val($(".customer_cpf_input").val());
        }
        let cardDigits = $(".credit_card_number_input")
          .val()
          .replace(/\s+/g, "");
        let cardholderName = $(".credit_card_titular_name_input").val();
        // let paymentCardExpiry = $(".credit_card_validate_input").val();
        let paymentCardExpiry = `${$(".month_validate_input").val()}/${$(
          ".year_validate_input"
        ).val()}`;
        let securityCode = $(".credit_card_security_code_input").val();
        let identificationNumber = $(".credit_card_titular_document_input")
          .val()
          .replace(/\D+/g, "");
        if (
          isPresent(cardDigits) &&
          isPresent(cardholderName) &&
          isPresent(paymentCardExpiry) &&
          isPresent(securityCode) &&
          isPresent(identificationNumber)
        ) {
          executeIntegrationsInitializers();
          initializeIntegrationsTimeout();
        } else {
          $("#button-submit-form-checkout").click();
        }
      } else if ($("#paymentKindTicket").hasClass("show")) {
        executeIntegrationsInitializers();
        initializeIntegrationsTimeout();
      } else {
        $("#button-submit-form-checkout").click();
      }
    });

    gateway_integrations_data_div.on("click", function () {
      if (timeoutIntegration) {
        clearTimeout(timeoutIntegration);
      }
      let completed_json = gateway_integrations_data_div.data("completed");
      if (Object.values(completed_json).every((v) => v === true)) {
        $("#button-submit-form-checkout").click();
      }
    });
  }
};

$(document).ready(function () {
  initializeListeners();
});

window.initializeListeners = initializeListeners;
