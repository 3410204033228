import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "verifyHide",
    "noCheckoutLabel",
    "onFinishLabel",
    "onSuccessLabel",
    "noCheckoutButton",
    "onFinishButton",
    "onSuccessButton",
  ];

  initialize() {
    this.toogle();
    this.removeLabelsFromInputs();
  }

  removeLabelsFromInputs() {
    let labels = document.querySelectorAll(".form-check-label");
    for (let label of labels) {
      label.classList.add("d-none");
    }
  }

  toogle() {
    if (this.noCheckoutButtonTarget.checked) {
      this.onFinishLabelTarget.classList.remove("active");
      this.onSuccessLabelTarget.classList.remove("active");
      this.noCheckoutLabelTarget.classList.add("active");
    } else if (this.onFinishButtonTarget.checked) {
      this.noCheckoutLabelTarget.classList.remove("active");
      this.onSuccessLabelTarget.classList.remove("active");
      this.onFinishLabelTarget.classList.add("active");
    } else {
      this.noCheckoutLabelTarget.classList.remove("active");
      this.onFinishLabelTarget.classList.remove("active");
      this.onSuccessLabelTarget.classList.add("active");
    }
  }
}
