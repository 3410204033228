import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "virtualFalse",
    "virtualTrue",
    "virtualFalseInput",
    "virtualTrueInput",
    "multipleFalse",
    "multipleTrue",
    "multipleFalseInput",
    "multipleTrueInput",
    "variationItem",
    "blockVariations",
    "grids",
  ];

  setVirtualAsTrue() {
    this.virtualTrueInputTarget.checked = true;
    this.virtualFalseInputTarget.checked = false;

    this.virtualTrueTarget.classList.add("border-primary", "shadow-sm");
    this.virtualFalseTarget.classList.remove("border-primary", "shadow-sm");

    $("#dimensionsBlock").hide("fast");
    $("#dimensionsBlockModal").hide("fast");
    // $("#skuBlock").hide("fast");
    // $("#skuBlockModal").hide("fast");
    // $(".notVirtualField").hide("fast");
    $(".fisicalBlock").hide("fast");
  }

  setVirtualAsFalse() {
    this.virtualFalseInputTarget.checked = true;
    this.virtualTrueInputTarget.checked = false;

    this.virtualFalseTarget.classList.add("border-primary", "shadow-sm");
    this.virtualTrueTarget.classList.remove("border-primary", "shadow-sm");

    // $("#skuBlock").show("fast");
    // $("#skuBlockModal").show("fast");
    // $(".notVirtualField").show("fast");
    $("#dimensionsBlock").show("fast");
    $("#dimensionsBlockModal").show("fast");
    $(".fisicalBlock").show("fast");
  }

  setMultipleAsTrue() {
    this.multipleTrueInputTarget.checked = true;
    this.multipleFalseInputTarget.checked = false;

    this.multipleTrueTarget.classList.add("border-primary", "shadow-sm");
    this.multipleFalseTarget.classList.remove("border-primary", "shadow-sm");

    $("#variations_item").html("");
    $(".grids").show("fast");
    $(".blockVariations").hide("fast");

    window.verifyProductVirtual();
  }

  setMultipleAsFalse() {
    this.multipleFalseInputTarget.checked = true;
    this.multipleTrueInputTarget.checked = false;

    this.multipleFalseTarget.classList.add("border-primary", "shadow-sm");
    this.multipleTrueTarget.classList.remove("border-primary", "shadow-sm");

    $("#product_grid_ids").prop("selectedIndex", -1);
    $(".grids").hide("fast");
    $(".blockVariations").show("fast");

    if ($(".nested-fields").length <= 0) {
      $("#add_variation")[0].click();
    }

    window.verifyProductVirtual();
  }
}
