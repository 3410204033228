import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "number",
    "holderName",
    "expMonth",
    "expYear",
    "cvv",
    "token"
  ];

  static values = {
    publicKey: String,
    testMode: Boolean,
  };

  async generateToken(event) {
    if (this.numberTarget.value != "" &&
      this.holderNameTarget.value != "" &&
      this.cvvTarget.value != "") {

      event.preventDefault();

      NoxPay.setPublicKey(this.publicKeyValue);
      NoxPay.setTestMode(this.testModeValue);

      var token = await NoxPay.encrypt({
        number: this.numberTarget.value,
        holderName: this.holderNameTarget.value,
        expMonth: String(this.expMonthTarget.value).padStart(2, '0'),
        expYear: this.expYearTarget.value,
        cvv: this.cvvTarget.value,
      });

      this.tokenTarget.value = token;

      event.target.submit();
    }
  }
}
