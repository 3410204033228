import consumer from "./consumer";

const elementStartSocket = document.querySelector("#start_websocket_pixel");
const key = Math.random();

if (elementStartSocket) {
  consumer.subscriptions.create(
    {
      channel: "PixelChannel",
    },
    {
      connected() {
        console.log("CONECTANDO DADOS");
        const button = document.querySelectorAll(".buttonSendPixel");
        if (button) {
          $(".buttonSendPixel").on("click", this.send_pixel.bind(this));
        }
      },

      received(data) {
        if (data?.reload) {
          window.location.reload();

          return;
        }
        if (data?.success) {
          var divHeadElement = document.createElement("script");
          divHeadElement.innerHTML = data?.pixel_head_facebook;
          $("head").append(divHeadElement);

          var divHeadElement = document.createElement("script");
          divHeadElement.innerHTML = data?.pixel_head_kwai;
          $("head").append(divHeadElement);

          var divHeadElement = document.createElement("script");
          divHeadElement.innerHTML = data?.pixel_head_tiktok;
          $("head").append(divHeadElement);

          var divHeadElement = document.createElement("script");
          divHeadElement.innerHTML = data?.pixel_head_taboola;
          $("head").append(divHeadElement);

          var divHeadElement = document.createElement("script");
          divHeadElement.innerHTML = data?.pixel_head_google;
          $("head").append(divHeadElement);

          var divElement = document.createElement("script");
          divElement.innerHTML = data?.facebook_html;
          document.getElementsByTagName("body")[0].appendChild(divElement);

          var divElement = document.createElement("script");
          divElement.innerHTML = data?.taboola_html;
          document.getElementsByTagName("body")[0].appendChild(divElement);

          var divElement = document.createElement("script");
          divElement.innerHTML = data?.tiktok_html;
          document.getElementsByTagName("body")[0].appendChild(divElement);

          var divElement = document.createElement("script");
          divElement.innerHTML = data?.kwai_html;
          document.getElementsByTagName("body")[0].appendChild(divElement);

          var divElement = document.createElement("script");
          divElement.innerHTML = data?.google_html;
          document.getElementsByTagName("body")[0].appendChild(divElement);

          // Aqui recebe os data do server side
          console.log("RECENDO data", data);

          this.perform("pixel_sended", {
            order_id: data.order_id,
            key_id: data.key_id,
          });
        }
      },

      reload_page(data) {
        if (data.key_id == key) {
          window.location.reload();
        }
      },

      send_pixel(e) {
        console.log(key);
        console.log("ENVIANDO PIXEL ", e.target.dataset.id);
        e.target.disabled = true;
        this.perform("send_pixel", {
          order_id: e.target.dataset.id,
          key_id: key,
        });
      },
    }
  );
}
