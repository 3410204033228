import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  getAllClients() {
    $.get(
      "/products/" + '' + "/get_same_types",
      function (data) {
        //console.log("list/products/same_types");
        //console.log(data);
        let x;
        $("#select2-plan_seller_ids-container").html("");
        for (x in data) {
          let option =
            '<option value ="' +
            data[x].id +
            '">' +
            data[x].name +
            "</option >";
          $("#select2-plan_seller_ids-container").append(option);
        }
      }
    );
  }
}