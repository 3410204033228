import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["utmFields", "checkUtmFields"];

  utmFieldsCheck() {
    console.log(this.checkUtmFieldsTarget.checked);
    if (this.checkUtmFieldsTarget.checked) {
      this.utmFieldsTarget.classList.remove("d-none");
    } else {
      this.utmFieldsTarget.classList.add("d-none");
    }
  }
}
