import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { last: Number };

  connect() {
    this.currentStep = 1;
    this.showStep(this.currentStep);
  }

  next() {
    if (this.currentStep < this.lastValue) {
      this.currentStep++;
      this.showStep(this.currentStep);
    }
  }

  previous() {
    if (this.currentStep > 1) {
      this.currentStep--;
      this.showStep(this.currentStep);
    }
  }

  showStep(step) {
    this.hideAllSteps();

    const stepElement = this.element.querySelector(`[data-step="${step}"]`);
    if (stepElement) {
      stepElement.classList.remove("d-none");
    }

    this.element.querySelectorAll("[data-step-indicator]").forEach((step) => {
      if (Number(step.dataset.stepIndicator) <= this.currentStep) {
        step.classList.add("active");
      }
    });
  }

  hideAllSteps() {
    this.element.querySelectorAll("[data-step]").forEach((step) => {
      step.classList.add("d-none");
    });

    this.element.querySelectorAll("[data-step-indicator]").forEach((step) => {
      step.classList.remove("active");
    });
  }
}
