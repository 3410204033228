import Card from "card";

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.initializeCardAnimateShowFields2();
    this.initializeCardAnimateShowFields();
  }

  connect() {
    this.applyTimer();
  }

  applyModel() {
    const inputTargets = Array.from(
      document.querySelectorAll('input[name="checkout_config[checkout_type]"]')
    );
    const selectedInput = inputTargets.find((input) => input.checked);

    if (selectedInput) {
      const model = selectedInput.value;

      if (model === "one_step") {
        document.querySelector("#one-step-form").classList.remove("d-none");
        document.querySelector("#multi-step-form").classList.add("d-none");
      } else if (model === "mult_step") {
        document.querySelector("#one-step-form").classList.add("d-none");
        document.querySelector("#multi-step-form").classList.remove("d-none");
      }
    }
  }

  hexToRgba(hex, alpha = 1) {
    // Remove o símbolo de hash se estiver presente
    hex = hex.replace(/^#/, "");

    // Expande hex curto (por exemplo, "#abc" para "#aabbcc")
    if (hex.length === 3) {
      hex = hex
        .split("")
        .map((char) => char + char)
        .join("");
    }

    // Converte as partes de r, g, b para decimal
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Retorna a cor em formato RGBA
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  applyButtons() {
    const checkedBgs = document.querySelectorAll(".checked-bg");
    const checkedBorders = document.querySelectorAll(".checked-border");
    const submitButtons = document.querySelectorAll("#submit-btn");
    const nextButtons = document.querySelectorAll(".next-btn");
    const color = document.querySelector("#checkout_config_fgcolor_btn").value;
    const bgColor = document.querySelector(
      "#checkout_config_bgcolor_btn"
    ).value;
    const label = document.querySelector("#checkout_config_label_btn").value;

    submitButtons.forEach((button) => {
      button.style.color = color;
      button.style.backgroundColor = bgColor;
      button.innerHTML = label;
    });

    nextButtons.forEach((button) => {
      button.style.color = color;
      button.style.backgroundColor = bgColor;
    });

    checkedBgs.forEach((element) => {
      element.style.borderColor = bgColor;
      element.style.backgroundColor = this.hexToRgba(bgColor, 0.15);
    });

    checkedBorders.forEach((element) => {
      element.style.borderColor = bgColor;
    });
  }

  timerInterval;

  applyTimer() {
    const banner = document.querySelector("#timer-banner");
    const timerChecked = document.querySelector(
      "#checkout_config_redirect_timer_active"
    ).checked;

    document.querySelector('input[name="checkout_config[timer]"]').value =
      timerChecked;

    if (timerChecked) {
      banner.classList.remove("d-none");
      banner.classList.add("d-flex");

      const color = document.querySelector(
        "#checkout_config_message_text_color"
      ).value;
      const bgColor = document.querySelector(
        "#checkout_config_timer_bg_color"
      ).value;
      const content = document.querySelector(
        'input[name="checkout_config[featured_text_content]"]'
      ).value;

      const timerDuration = document.querySelector(
        "#checkout_config_timer_duration_minutes"
      ).value;

      const bannerCountdown = document.querySelector("#timer-banner-countdown");

      if (timerDuration) {
        bannerCountdown.classList.remove("d-none");
      } else {
        bannerCountdown.classList.add("d-none");
      }

      const timerDurationElement = document.querySelector("#timer-duration");

      let timeLeft = timerDuration * 60;
      clearInterval(this.timerInterval);

      this.timerInterval = setInterval(() => {
        let hours = Math.floor(timeLeft / 3600);
        let minutes = Math.floor((timeLeft % 3600) / 60);
        let seconds = timeLeft % 60;

        if (hours < 10) {
          hours = "0".concat(hours);
        }

        if (seconds < 10) {
          seconds = "0".concat(seconds);
        }

        if (minutes < 10) {
          minutes = "0".concat(minutes);
        }

        timerDurationElement.innerHTML = `${hours}:${minutes}:${seconds}`;

        timeLeft--;

        if (timeLeft < 0) {
          clearInterval(this.timerInterval);
        }
      }, 1000);

      const bannerContent = document.querySelector("#timer-banner-content");
      if (content) {
        bannerContent.classList.remove("d-none");
        bannerContent.innerHTML = content;
      } else {
        bannerContent.classList.add("d-none");
      }

      banner.style.backgroundColor = bgColor;
      bannerContent.style.color = color;
    } else {
      banner.classList.add("d-none");
      banner.classList.remove("d-flex");
    }
  }

  applyDiscountCoupon() {
    const hasCoupon = document.querySelector(
      "#checkout_config_has_coupon"
    ).checked;
    const discountCouponElement = document.querySelectorAll(
      "#discount-coupon-element"
    );

    if (hasCoupon) {
      discountCouponElement.forEach((element) => {
        element.classList.remove("d-none");
      });
    } else {
      discountCouponElement.forEach((element) => {
        element.classList.add("d-none");
      });
    }
  }

  applyDiscount() {
    //card
    const cardDiscount = document.querySelector(
      "#checkout_config_accumulative_card_discount"
    ).checked;
    const cardDiscountValue = parseInt(
      document.querySelector("#checkout_config_discount_card").value,
      10
    );
    const cardDiscountElements = document.querySelectorAll(
      "#card-discount-element"
    );

    if (cardDiscount) {
      cardDiscountElements.forEach((element) => {
        element.classList.remove("d-none");

        element.querySelector(
          "#card-discount-value"
        ).innerHTML = `${cardDiscountValue}%`;
      });
    } else {
      cardDiscountElements.forEach((element) => {
        element.classList.add("d-none");
      });
    }

    // pix
    const pixDiscount = document.querySelector(
      "#checkout_config_accumulative_pix_discount"
    ).checked;
    const pixDiscountValue = parseInt(
      document.querySelector("#checkout_config_discount_pix").value,
      10
    );
    const pixDiscountElements = document.querySelectorAll(
      "#pix-discount-element"
    );

    if (pixDiscount) {
      pixDiscountElements.forEach((element) => {
        element.classList.remove("d-none");
        element.querySelector(
          "#pix-discount-value"
        ).innerHTML = `${pixDiscountValue}%`;
      });
    } else {
      pixDiscountElements.forEach((element) => {
        element.classList.add("d-none");
      });
    }

    // bank slip
    const bankSlipDiscount = document.querySelector(
      "#checkout_config_accumulative_bank_slip_discount"
    ).checked;
    const bankSlipDiscountValue = parseInt(
      document.querySelector("#checkout_config_discount_bank_slip").value,
      10
    );
    const bankSlipDiscountElements = document.querySelectorAll(
      "#bank-slip-discount-element"
    );

    if (bankSlipDiscount) {
      bankSlipDiscountElements.forEach((element) => {
        element.classList.remove("d-none");
        element.querySelector(
          "#bank-slip-discount-value"
        ).innerHTML = `${bankSlipDiscountValue}%`;
      });
    } else {
      bankSlipDiscountElements.forEach((element) => {
        element.classList.add("d-none");
      });
    }
  }

  applyPaymentKind() {
    const quantitySelector = document.querySelector(
      "#checkout_config_quantity_selector"
    ).checked;

    if (quantitySelector) {
      document.querySelectorAll("#quantity-element").forEach((element) => {
        element.classList.remove("d-none");
      });
    } else {
      document.querySelectorAll("#quantity-element").forEach((element) => {
        element.classList.add("d-none");
      });
    }

    //card
    const card = document.querySelector(
      "#checkout_config_payment_via_card"
    ).checked;
    const cardElements = document.querySelectorAll("#card-option");
    const cardSelectorElements = document.querySelectorAll("#card-selector");

    if (card) {
      cardSelectorElements.forEach((element) => {
        element.classList.remove("d-none");
      });
      cardElements.forEach((element) => {
        element.classList.remove("d-none");
      });
    } else {
      cardSelectorElements.forEach((element) => {
        element.classList.add("d-none");
      });
      cardElements.forEach((element) => {
        element.classList.add("d-none");
      });
    }

    //pix
    const pix = document.querySelector(
      "#checkout_config_payment_via_pix"
    ).checked;
    const pixElements = document.querySelectorAll("#pix-option");
    const pixSelectorElements = document.querySelectorAll("#pix-selector");

    if (pix) {
      pixSelectorElements.forEach((element) => {
        element.classList.remove("d-none");
      });
      pixElements.forEach((element) => {
        element.classList.remove("d-none");
      });
    } else {
      pixSelectorElements.forEach((element) => {
        element.classList.add("d-none");
      });
      pixElements.forEach((element) => {
        element.classList.add("d-none");
      });
    }

    //bank slip
    const bankSlip = document.querySelector(
      "#checkout_config_payment_via_bank_slip"
    ).checked;
    const bankSlipElements = document.querySelectorAll("#bank-slip-option");
    const bankSlipSelectorElements = document.querySelectorAll(
      "#bank-slip-selector"
    );

    if (bankSlip) {
      bankSlipSelectorElements.forEach((element) => {
        element.classList.remove("d-none");
      });
      bankSlipElements.forEach((element) => {
        element.classList.remove("d-none");
      });
    } else {
      bankSlipSelectorElements.forEach((element) => {
        element.classList.add("d-none");
      });
      bankSlipElements.forEach((element) => {
        element.classList.add("d-none");
      });
    }

    const paymentType = document.querySelector(
      "#checkout_config_default_payment_type"
    ).value;

    const cardCollapses = document.querySelectorAll(".collapseCreditCard");
    const cardInputs = document.querySelectorAll("#payment_kind_credit_card");
    const pixCollapses = document.querySelectorAll(".collapsePix");
    const pixInputs = document.querySelectorAll("#payment_kind_pix");

    const bankSlipCollapses = document.querySelectorAll(".collapseBankSlip");
    const bankSlipInputs = document.querySelectorAll("#payment_kind_bank_slip");

    switch (paymentType) {
      case "credit_card":
        cardInputs.forEach((element) => {
          element.checked = true;
        });
        cardCollapses.forEach((element) => {
          element.classList.add("show");
        });
        pixCollapses.forEach((element) => {
          element.classList.remove("show");
        });
        bankSlipCollapses.forEach((element) => {
          element.classList.remove("show");
        });
        break;
      case "pix":
        pixInputs.forEach((element) => {
          element.checked = true;
        });
        cardCollapses.forEach((element) => {
          element.classList.remove("show");
        });
        pixCollapses.forEach((element) => {
          element.classList.add("show");
        });
        bankSlipCollapses.forEach((element) => {
          element.classList.remove("show");
        });
        break;
      case "bank_slip":
        bankSlipInputs.forEach((element) => {
          element.checked = true;
        });
        cardCollapses.forEach((element) => {
          element.classList.remove("show");
        });
        pixCollapses.forEach((element) => {
          element.classList.remove("show");
        });
        bankSlipCollapses.forEach((element) => {
          element.classList.add("show");
        });
        break;

      default:
        cardInputs.forEach((element) => {
          element.checked = false;
        });
        pixInputs.forEach((element) => {
          element.checked = false;
        });
        bankSlipInputs.forEach((element) => {
          element.checked = false;
        });
        cardCollapses.forEach((element) => {
          element.classList.remove("show");
        });
        pixCollapses.forEach((element) => {
          element.classList.remove("show");
        });
        bankSlipCollapses.forEach((element) => {
          element.classList.remove("show");
        });
        break;
    }
  }

  applyUrls() {}

  setMobileView() {
    const desktopButton = document.querySelector("#desktop-button");
    const mobileButton = document.querySelector("#mobile-button");
    const container = document.querySelector(".checkout-config");

    container.classList.add("mobile-view");
    desktopButton.classList.remove("active");
    mobileButton.classList.add("active");
  }

  unsetMobileView() {
    const desktopButton = document.querySelector("#desktop-button");
    const mobileButton = document.querySelector("#mobile-button");
    const container = document.querySelector(".checkout-config");

    container.classList.remove("mobile-view");
    desktopButton.classList.add("active");
    mobileButton.classList.remove("active");
  }

  initializeCardAnimateShowFields() {
    new Card({
      form: "#collapseCreditCardoneStep",
      container: ".card-wrapperoneStep",

      formSelectors: {
        numberInput: "#credit_card_numberoneStep",
        nameInput: "#credit_card_titular_nameoneStep",
        expiryInput: "#credit_card_validateoneStep",
        cvcInput: "#credit_card_security_codeoneStep",
      },

      placeholders: {
        name: "Nome",
      },

      width: 270,
    });
  }

  initializeCardAnimateShowFields2() {
    new Card({
      form: "#collapseCreditCardmultiStep",
      container: ".card-wrappermultiStep",

      formSelectors: {
        numberInput: "#credit_card_numbermultiStep",
        nameInput: "#credit_card_titular_namemultiStep",
        expiryInput: "#credit_card_validatemultiStep",
        cvcInput: "#credit_card_security_codemultiStep",
      },

      placeholders: {
        name: "Nome",
      },

      width: 270,
    });
  }
}
