import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";

window.PNotify = PNotify;

PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome4";
PNotify.defaults.stack = {
  dir1: "down",
  // dir2: "right",
  firstpos1: 8,
  // firstpos2: 8,
};
PNotify.defaults.delay = 3000;
